import React from "react";
import CustomImageLogoDiv from "./logoDiv";
interface PropTypes {
  hideBackButton?: any,
  onBack?: any
}
const CustomLogo: React.FC<PropTypes> = (props) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 0,
        }}
      >
        <div
          style={{
            position: "absolute",
            left: "50px",
            marginTop: 15,
          }}
        >
          {props.hideBackButton ? null : (
            <div onClick={() => props.onBack()} className="isoForgotPass" style={{ cursor: "pointer" }}>
              <img
                src={"/images/arrowLeft2.png"}
                alt="arrowLeft"
                style={{
                  height: 30,
                  width: 30,
                }}
              />
            </div>
          )}
        </div>
        <div className="isoLogoWrapper">
          <CustomImageLogoDiv />
          <div>
            <p>Bookingo</p>
            <p style={{ fontSize: 12 }}>Powered by illumine</p>
          </div>
        </div>
      </div>
    </>
  );
};
export default CustomLogo;
